/* You can add global styles to this file, and also import other style files */

.network-drpodown img {
    align-self: center;
}
.network-drpodown button span {
    font-size: 1.5rem;
    font-weight: 700;
    text-transform : capitalize;
    color: #1B96FF;
    float: left;
    margin: 5px;
}
.network-drpodown .slds-dropdown__item>a {
    justify-content: left !important;
}
.add-network svg {
    fill: #76c0ff;
}
.edit-drpodown button{
    height: 52px;
    width: 34px;
    margin-right: 3px;
}

.dropdown_logo {
    background: white;
    border-radius: 100%;
    border: solid 1px #c9c9c9;
    display: flex;
    height: 2.5rem;
    justify-content: center;
    overflow: hidden;
    width: 2.5rem;
}
.dropdown_logo img {
    height: 25px;
}

.dropdown_item {
    width: 280px;
    text-transform: capitalize;
}

.mapboxgl-popup-content {
    min-width: 20rem;
}

.mapboxgl-popup {
    max-width: 20rem !important;
}

.slds-media {
    padding: 1rem 1rem 0 1rem;
}

.mapboxgl-popup-close-button {
    font-size: 1.5rem;
}

.slds-dropdown::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #eaeaea;
    border-radius: 10px;
}

.slds-dropdown::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ccc;
}